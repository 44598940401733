var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"xs":"12","sm":"2","md":"1"}},[_c('v-btn',{attrs:{"dark":"","block":"","color":"rgb(231,87,83)","to":{
          name: 'AccountList',
        }}},[_vm._v("Back")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
          'items-per-page-options': [10, 20, 30]
        },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s((_vm.options.page * 10) - (10 - (_vm.items.indexOf(item) + 1) )))])]}},{key:"item.didStatus",fn:function(ref){
        var item = ref.item;
return [(item.didStatus == 'DID_PENDING_ALLOCA')?_c('span',[_vm._v("Pending Allocation")]):_vm._e(),(item.didStatus == 'DID_ACTIVATED')?_c('span',[_vm._v("Activated")]):_vm._e(),(item.didStatus == 'DID_INACTIVE')?_c('span',[_vm._v("Inactivated")]):_vm._e(),(item.didStatus == 'DID_SUSPENDED')?_c('span',[_vm._v("Suspended")]):_vm._e(),(item.didStatus == 'DID_EXPIRED')?_c('span',[_vm._v("Expired")]):_vm._e(),(item.didStatus == 'DID_TERMINATED')?_c('span',[_vm._v("Terminated")]):_vm._e(),(item.didStatus == 'DID_DISABLED')?_c('span',[_vm._v("Disabled")]):_vm._e(),(item.didStatus == null)?_c('span',[_vm._v("-")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }